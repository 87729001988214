"use client"

import { usePathname, useRouter } from "next/navigation"
import { useUser } from "@/hooks/use-user"
import { SignedIn, UserButton } from "@clerk/nextjs"
import { HomeIcon, Loader2 } from "lucide-react"

import { LogoIcon } from "@/components/logo-icon"

export const UserMenu = () => {
  const path = usePathname()
  const route = useRouter()
  const { user, isSignedIn } = useUser()

  const redirectToMergie = () => {
    route.push(`/${user?.username}`)
  }

  if (isSignedIn && !user?.username) return <Loader2 className="animate-spin" />
  return (
    <SignedIn key={user?.username}>
      {/* Mount the UserButton component */}
      <UserButton userProfileUrl="/me/analytics">
        {path != "/" && (
          <UserButton.MenuItems>
            <UserButton.Link
              label="Back to home"
              labelIcon={<HomeIcon size={16} />}
              href={`/`}
            />
          </UserButton.MenuItems>
        )}
        {path != `/${user?.username}` && (
          <UserButton.MenuItems>
            <UserButton.Action
              label={`My Mergie`}
              labelIcon={<LogoIcon />}
              onClick={redirectToMergie}
            />
          </UserButton.MenuItems>
        )}
      </UserButton>
    </SignedIn>
  )
}
