/* eslint-disable */
import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Time: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type DashboardUserBioRequest = {
  endDate: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export type DashboardUserBioResponse = {
  __typename?: 'DashboardUserBioResponse';
  topAction: Array<DashboardUserBioValuesResponse>;
  topCountry: Array<DashboardUserBioValuesResponse>;
  topDevice: Array<DashboardUserBioValuesResponse>;
  topPlatform: Array<DashboardUserBioValuesResponse>;
  topRefSource: Array<DashboardUserBioValuesResponse>;
  topWidgetClick: Array<DashboardUserBioValuesResponse>;
};

export type DashboardUserBioValuesResponse = {
  __typename?: 'DashboardUserBioValuesResponse';
  name: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  finishOnboarding: Scalars['Boolean']['output'];
  sendAnalytics: Scalars['Boolean']['output'];
  updateUserBioCards: Scalars['Boolean']['output'];
  updateUserBioData: Scalars['Boolean']['output'];
  updateUserName: Scalars['Boolean']['output'];
  uploadSingleFile: UploadSingleFileResponse;
};


export type MutationSendAnalyticsArgs = {
  input: SendAnalyticsRequest;
};


export type MutationUpdateUserBioCardsArgs = {
  input: UpdateUserBioCardsRequest;
};


export type MutationUpdateUserBioDataArgs = {
  input: UpdateUserBioDataRequest;
};


export type MutationUpdateUserNameArgs = {
  username: Scalars['String']['input'];
};


export type MutationUploadSingleFileArgs = {
  file: Scalars['Upload']['input'];
  path: Scalars['String']['input'];
};

export type PaginationFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginationFilterResponse = {
  __typename?: 'PaginationFilterResponse';
  HasNextPage: Scalars['Boolean']['output'];
  HasPrevPage: Scalars['Boolean']['output'];
  Limit: Scalars['Int']['output'];
  Page: Scalars['Int']['output'];
  Total: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  dashboardUserBio: DashboardUserBioResponse;
  health: Scalars['Boolean']['output'];
  scrape: ScrapeResponse;
  syncAccount: SyncAccountResponse;
  userDetail: UserDetailResponse;
  userDetailBios: UserDetailBiosResponse;
  userDetailPublic: UserDetailPublicResponse;
};


export type QueryDashboardUserBioArgs = {
  input: DashboardUserBioRequest;
};


export type QueryScrapeArgs = {
  input: ScrapeRequest;
};


export type QueryUserDetailPublicArgs = {
  username: Scalars['String']['input'];
};

export type ScrapeRequest = {
  skipCache?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type ScrapeResponse = {
  __typename?: 'ScrapeResponse';
  description: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  image: Scalars['String']['output'];
  meta: Array<ScrapeResponseMeta>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ScrapeResponseMeta = {
  __typename?: 'ScrapeResponseMeta';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SendAnalyticsMetaRequest = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SendAnalyticsRequest = {
  action: Scalars['String']['input'];
  actionType?: InputMaybe<Scalars['String']['input']>;
  actionValue?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Array<InputMaybe<SendAnalyticsMetaRequest>>>;
  source: Scalars['String']['input'];
  userBioId: Scalars['String']['input'];
};

export type SyncAccountResponse = {
  __typename?: 'SyncAccountResponse';
  isNewUser: Scalars['Boolean']['output'];
  user: UserDetailResponse;
};

export type UpdateUserBioCardsRequest = {
  value: Scalars['String']['input'];
};

export type UpdateUserBioDataRequest = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UserDetailBiosResponse = {
  __typename?: 'UserDetailBiosResponse';
  cardJsonString?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
};

export type UserDetailMetaResponse = {
  __typename?: 'UserDetailMetaResponse';
  isFinishOnboarding: Scalars['Boolean']['output'];
};

export type UserDetailPublicResponse = {
  __typename?: 'UserDetailPublicResponse';
  cardJsonString?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  userBioId: Scalars['String']['output'];
};

export type UserDetailResponse = {
  __typename?: 'UserDetailResponse';
  createdAt: Scalars['Time']['output'];
  email: Scalars['String']['output'];
  meta: UserDetailMetaResponse;
  updatedAt: Scalars['Time']['output'];
  username: Scalars['String']['output'];
};

export type UploadSingleFileResponse = {
  __typename?: 'uploadSingleFileResponse';
  url: Scalars['String']['output'];
};

export type UserDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDetailQuery = { __typename?: 'Query', userDetail: { __typename?: 'UserDetailResponse', username: string, email: string, createdAt: any, updatedAt: any, meta: { __typename?: 'UserDetailMetaResponse', isFinishOnboarding: boolean } } };

export type UserDetailPublicQueryQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UserDetailPublicQueryQuery = { __typename?: 'Query', userDetailPublic: { __typename?: 'UserDetailPublicResponse', userBioId: string, name?: string | null, thumbnail?: string | null, description?: string | null, cardJsonString?: string | null } };

export type MyBioQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type MyBioQueryQuery = { __typename?: 'Query', userDetailBios: { __typename?: 'UserDetailBiosResponse', name?: string | null, description?: string | null, thumbnail?: string | null, cardJsonString?: string | null } };

export type UpdateUserNameMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UpdateUserNameMutation = { __typename?: 'Mutation', updateUserName: boolean };

export type UpdateUserBioCardsMutationVariables = Exact<{
  input: UpdateUserBioCardsRequest;
}>;


export type UpdateUserBioCardsMutation = { __typename?: 'Mutation', updateUserBioCards: boolean };

export type UpdateUserBioDataMutationVariables = Exact<{
  input: UpdateUserBioDataRequest;
}>;


export type UpdateUserBioDataMutation = { __typename?: 'Mutation', updateUserBioData: boolean };

export type SyncAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncAccountQuery = { __typename?: 'Query', syncAccount: { __typename?: 'SyncAccountResponse', isNewUser: boolean, user: { __typename?: 'UserDetailResponse', username: string, meta: { __typename?: 'UserDetailMetaResponse', isFinishOnboarding: boolean } } } };

export type UploadSingleFileMutationVariables = Exact<{
  path: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
}>;


export type UploadSingleFileMutation = { __typename?: 'Mutation', uploadSingleFile: { __typename?: 'uploadSingleFileResponse', url: string } };

export type FinishOnboardingMutationVariables = Exact<{ [key: string]: never; }>;


export type FinishOnboardingMutation = { __typename?: 'Mutation', finishOnboarding: boolean };

export type ScrapeQueryVariables = Exact<{
  input: ScrapeRequest;
}>;


export type ScrapeQuery = { __typename?: 'Query', scrape: { __typename?: 'ScrapeResponse', url: string, title: string, description: string, icon: string, image: string, domain: string, meta: Array<{ __typename?: 'ScrapeResponseMeta', name: string, value: string }> } };

export type DashboardUserBioQueryVariables = Exact<{
  input: DashboardUserBioRequest;
}>;


export type DashboardUserBioQuery = { __typename?: 'Query', dashboardUserBio: { __typename?: 'DashboardUserBioResponse', topAction: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }>, topCountry: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }>, topDevice: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }>, topPlatform: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }>, topRefSource: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }>, topWidgetClick: Array<{ __typename?: 'DashboardUserBioValuesResponse', name: string, value: number }> } };

export type SendAnalyticsMutationVariables = Exact<{
  input: SendAnalyticsRequest;
}>;


export type SendAnalyticsMutation = { __typename?: 'Mutation', sendAnalytics: boolean };

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any> | undefined) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const UserDetailDocument = new TypedDocumentString(`
    query UserDetail {
  userDetail {
    username
    email
    createdAt
    updatedAt
    meta {
      isFinishOnboarding
    }
  }
}
    `) as unknown as TypedDocumentString<UserDetailQuery, UserDetailQueryVariables>;
export const UserDetailPublicQueryDocument = new TypedDocumentString(`
    query userDetailPublicQuery($username: String!) {
  userDetailPublic(username: $username) {
    userBioId
    name
    thumbnail
    description
    cardJsonString
  }
}
    `) as unknown as TypedDocumentString<UserDetailPublicQueryQuery, UserDetailPublicQueryQueryVariables>;
export const MyBioQueryDocument = new TypedDocumentString(`
    query myBioQuery {
  userDetailBios {
    name
    description
    thumbnail
    cardJsonString
  }
}
    `) as unknown as TypedDocumentString<MyBioQueryQuery, MyBioQueryQueryVariables>;
export const UpdateUserNameDocument = new TypedDocumentString(`
    mutation updateUserName($username: String!) {
  updateUserName(username: $username)
}
    `) as unknown as TypedDocumentString<UpdateUserNameMutation, UpdateUserNameMutationVariables>;
export const UpdateUserBioCardsDocument = new TypedDocumentString(`
    mutation updateUserBioCards($input: UpdateUserBioCardsRequest!) {
  updateUserBioCards(input: $input)
}
    `) as unknown as TypedDocumentString<UpdateUserBioCardsMutation, UpdateUserBioCardsMutationVariables>;
export const UpdateUserBioDataDocument = new TypedDocumentString(`
    mutation updateUserBioData($input: UpdateUserBioDataRequest!) {
  updateUserBioData(input: $input)
}
    `) as unknown as TypedDocumentString<UpdateUserBioDataMutation, UpdateUserBioDataMutationVariables>;
export const SyncAccountDocument = new TypedDocumentString(`
    query syncAccount {
  syncAccount {
    isNewUser
    user {
      username
      meta {
        isFinishOnboarding
      }
    }
  }
}
    `) as unknown as TypedDocumentString<SyncAccountQuery, SyncAccountQueryVariables>;
export const UploadSingleFileDocument = new TypedDocumentString(`
    mutation uploadSingleFile($path: String!, $file: Upload!) {
  uploadSingleFile(file: $file, path: $path) {
    url
  }
}
    `) as unknown as TypedDocumentString<UploadSingleFileMutation, UploadSingleFileMutationVariables>;
export const FinishOnboardingDocument = new TypedDocumentString(`
    mutation finishOnboarding {
  finishOnboarding
}
    `) as unknown as TypedDocumentString<FinishOnboardingMutation, FinishOnboardingMutationVariables>;
export const ScrapeDocument = new TypedDocumentString(`
    query scrape($input: ScrapeRequest!) {
  scrape(input: $input) {
    url
    title
    description
    icon
    image
    domain
    meta {
      name
      value
    }
  }
}
    `) as unknown as TypedDocumentString<ScrapeQuery, ScrapeQueryVariables>;
export const DashboardUserBioDocument = new TypedDocumentString(`
    query dashboardUserBio($input: DashboardUserBioRequest!) {
  dashboardUserBio(input: $input) {
    topAction {
      name
      value
    }
    topCountry {
      name
      value
    }
    topDevice {
      name
      value
    }
    topPlatform {
      name
      value
    }
    topRefSource {
      name
      value
    }
    topWidgetClick {
      name
      value
    }
  }
}
    `) as unknown as TypedDocumentString<DashboardUserBioQuery, DashboardUserBioQueryVariables>;
export const SendAnalyticsDocument = new TypedDocumentString(`
    mutation sendAnalytics($input: SendAnalyticsRequest!) {
  sendAnalytics(input: $input)
}
    `) as unknown as TypedDocumentString<SendAnalyticsMutation, SendAnalyticsMutationVariables>;