import { getAuthToken } from "@/api/auth-token-client"
import type { TypedDocumentString } from "@/graphql/graphql"

export async function execute<TResult, TVariables>(
  query: TypedDocumentString<TResult, TVariables>,
  options?: {
    variables?: TVariables
    token?: string
  }
) {
  const token = options?.token ?? (await getAuthToken?.())
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/graphql-response+json",
  }

  if (token) {
    headers["Authorization"] = `Bearer ${token}`
  }
  const response = await fetch(process.env.NEXT_PUBLIC_API_SCHEMA_URL, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: JSON.stringify({
      query: query,
      variables: options?.variables,
    }),
  })

  if (!response.ok) {
    throw new Error("Network response was not ok")
  }

  const { errors, data } = await response.json()

  if (errors != null || errors?.length > 0) {
    throw new Error(errors[0]?.message, {
      cause: {
        code: errors?.[0]?.extensions?.code || 500,
      },
    })
  }
  return data as TResult
}
