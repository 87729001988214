import { GitHubLogoIcon } from "@radix-ui/react-icons"
import {
  Laptop,
  Moon,
  SunMedium,
  X,
  type Icon as LucideIconT,
} from "lucide-react"

export { LucideIconT as Icon }

export const Icons = {
  sun: SunMedium,
  moon: Moon,
  laptop: Laptop,
  twitter: X,
  github: GitHubLogoIcon
}
