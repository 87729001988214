/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n  query UserDetail {\n    userDetail {\n      username\n      email\n      createdAt\n      updatedAt\n      meta {\n        isFinishOnboarding\n      }\n    }\n  }\n": types.UserDetailDocument,
    "\n  query userDetailPublicQuery($username: String!) {\n    userDetailPublic(username: $username) {\n      userBioId\n      name\n      thumbnail\n      description\n      cardJsonString\n    }\n  }\n": types.UserDetailPublicQueryDocument,
    "\n  query myBioQuery {\n    userDetailBios {\n      name\n      description\n      thumbnail\n      cardJsonString\n    }\n  }\n": types.MyBioQueryDocument,
    "\n  mutation updateUserName($username: String!) {\n    updateUserName(username: $username)\n  }\n": types.UpdateUserNameDocument,
    "\n  mutation updateUserBioCards($input: UpdateUserBioCardsRequest!) {\n    updateUserBioCards(input: $input)\n  }\n": types.UpdateUserBioCardsDocument,
    "\n  mutation updateUserBioData($input: UpdateUserBioDataRequest!) {\n    updateUserBioData(input: $input)\n  }\n": types.UpdateUserBioDataDocument,
    "\n  query syncAccount {\n    syncAccount {\n      isNewUser\n      user {\n        username\n        meta {\n          isFinishOnboarding\n        }\n      }\n    }\n  }\n": types.SyncAccountDocument,
    "\n  mutation uploadSingleFile($path: String!, $file: Upload!) {\n    uploadSingleFile(file: $file, path: $path) {\n      url\n    }\n  }\n": types.UploadSingleFileDocument,
    "\n  mutation finishOnboarding {\n    finishOnboarding\n  }\n": types.FinishOnboardingDocument,
    "\n  query scrape($input: ScrapeRequest!) {\n    scrape(input: $input) {\n      url\n      title\n      description\n      icon\n      image\n      domain\n      meta{\n        name\n        value\n      }\n    }\n  }\n": types.ScrapeDocument,
    "\n  query dashboardUserBio($input: DashboardUserBioRequest!) {\n    dashboardUserBio(input: $input) {\n      topAction {\n        name\n        value\n      }\n      topCountry {\n        name\n        value\n      }\n      topDevice {\n        name\n        value\n      }\n      topPlatform {\n        name\n        value\n      }\n      topRefSource {\n        name\n        value\n      }\n      topWidgetClick {\n        name\n        value\n      }\n    }\n  }\n": types.DashboardUserBioDocument,
    "\n  mutation sendAnalytics($input: SendAnalyticsRequest!) {\n    sendAnalytics(input: $input)\n  }\n": types.SendAnalyticsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserDetail {\n    userDetail {\n      username\n      email\n      createdAt\n      updatedAt\n      meta {\n        isFinishOnboarding\n      }\n    }\n  }\n"): typeof import('./graphql').UserDetailDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userDetailPublicQuery($username: String!) {\n    userDetailPublic(username: $username) {\n      userBioId\n      name\n      thumbnail\n      description\n      cardJsonString\n    }\n  }\n"): typeof import('./graphql').UserDetailPublicQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query myBioQuery {\n    userDetailBios {\n      name\n      description\n      thumbnail\n      cardJsonString\n    }\n  }\n"): typeof import('./graphql').MyBioQueryDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUserName($username: String!) {\n    updateUserName(username: $username)\n  }\n"): typeof import('./graphql').UpdateUserNameDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUserBioCards($input: UpdateUserBioCardsRequest!) {\n    updateUserBioCards(input: $input)\n  }\n"): typeof import('./graphql').UpdateUserBioCardsDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUserBioData($input: UpdateUserBioDataRequest!) {\n    updateUserBioData(input: $input)\n  }\n"): typeof import('./graphql').UpdateUserBioDataDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query syncAccount {\n    syncAccount {\n      isNewUser\n      user {\n        username\n        meta {\n          isFinishOnboarding\n        }\n      }\n    }\n  }\n"): typeof import('./graphql').SyncAccountDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation uploadSingleFile($path: String!, $file: Upload!) {\n    uploadSingleFile(file: $file, path: $path) {\n      url\n    }\n  }\n"): typeof import('./graphql').UploadSingleFileDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation finishOnboarding {\n    finishOnboarding\n  }\n"): typeof import('./graphql').FinishOnboardingDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query scrape($input: ScrapeRequest!) {\n    scrape(input: $input) {\n      url\n      title\n      description\n      icon\n      image\n      domain\n      meta{\n        name\n        value\n      }\n    }\n  }\n"): typeof import('./graphql').ScrapeDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query dashboardUserBio($input: DashboardUserBioRequest!) {\n    dashboardUserBio(input: $input) {\n      topAction {\n        name\n        value\n      }\n      topCountry {\n        name\n        value\n      }\n      topDevice {\n        name\n        value\n      }\n      topPlatform {\n        name\n        value\n      }\n      topRefSource {\n        name\n        value\n      }\n      topWidgetClick {\n        name\n        value\n      }\n    }\n  }\n"): typeof import('./graphql').DashboardUserBioDocument;
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation sendAnalytics($input: SendAnalyticsRequest!) {\n    sendAnalytics(input: $input)\n  }\n"): typeof import('./graphql').SendAnalyticsDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
