import { useEffect } from "react"
import { execute } from "@/api/execute"
import { myDetailQuery } from "@/api/user"
import { useAuth } from "@clerk/nextjs"
import { atom, useAtom, useAtomValue } from "jotai"
import { atomWithQuery } from "jotai-tanstack-query"

export const tokenReadyAtom = atom(false)
const userAtom = atomWithQuery((get) => ({
  queryKey: ["users", get(tokenReadyAtom)],
  queryFn: async ({ queryKey: [, token] }) => {
    if (!token) return null
    const data = await execute(myDetailQuery)
    return data
  },
}))

export const useUser = () => {
  const { isSignedIn, getToken, signOut } = useAuth()
  const atoms = useAtomValue(userAtom)

  const [, setTokenReady] = useAtom(tokenReadyAtom)
  useEffect(() => {
    async function fetchUserToken() {
      if (isSignedIn) {
        const token = await getToken()

        setTokenReady(true)
      }
    }
    fetchUserToken()
  }, [getToken, isSignedIn, setTokenReady])

  useEffect(() => {
    if (
      atoms.error?.message === "Unauthorized" &&
      (atoms.error?.cause as any)?.code === 401
    ) {
      signOut()
    }
  }, [atoms.error, signOut])

  return { ...atoms, user: atoms?.data?.userDetail, isSignedIn }
}
