export async function getAuthToken() {
  const isServer = typeof window === "undefined"
  if (isServer) return {}
  // if (isServer) {
  //   ;("use server")
  //   const { getToken } = auth()
  //   return await getToken()
  // }

  // @ts-ignore
  return window?.Clerk?.session?.getToken?.()
}
