import { graphql } from "@/graphql/gql"

export const myDetailQuery = graphql(`
  query UserDetail {
    userDetail {
      username
      email
      createdAt
      updatedAt
      meta {
        isFinishOnboarding
      }
    }
  }
`)

export const userDetailPublicQuery = graphql(`
  query userDetailPublicQuery($username: String!) {
    userDetailPublic(username: $username) {
      userBioId
      name
      thumbnail
      description
      cardJsonString
    }
  }
`)

export const myBioQuery = graphql(`
  query myBioQuery {
    userDetailBios {
      name
      description
      thumbnail
      cardJsonString
    }
  }
`)

export const updateUsername = graphql(`
  mutation updateUserName($username: String!) {
    updateUserName(username: $username)
  }
`)

export const updateUserBioCards = graphql(`
  mutation updateUserBioCards($input: UpdateUserBioCardsRequest!) {
    updateUserBioCards(input: $input)
  }
`)

export const updateUserBioData = graphql(`
  mutation updateUserBioData($input: UpdateUserBioDataRequest!) {
    updateUserBioData(input: $input)
  }
`)

export const syncAccount = graphql(`
  query syncAccount {
    syncAccount {
      isNewUser
      user {
        username
        meta {
          isFinishOnboarding
        }
      }
    }
  }
`)

export const uploadSingleFile = graphql(`
  mutation uploadSingleFile($path: String!, $file: Upload!) {
    uploadSingleFile(file: $file, path: $path) {
      url
    }
  }
`)

export const finishOnboarding = graphql(`
  mutation finishOnboarding {
    finishOnboarding
  }
`)

export const scrape = graphql(`
  query scrape($input: ScrapeRequest!) {
    scrape(input: $input) {
      url
      title
      description
      icon
      image
      domain
      meta{
        name
        value
      }
    }
  }
`)

export const dashboardUserBio = graphql(`
  query dashboardUserBio($input: DashboardUserBioRequest!) {
    dashboardUserBio(input: $input) {
      topAction {
        name
        value
      }
      topCountry {
        name
        value
      }
      topDevice {
        name
        value
      }
      topPlatform {
        name
        value
      }
      topRefSource {
        name
        value
      }
      topWidgetClick {
        name
        value
      }
    }
  }
`)

export const sendAnalytics = graphql(`
  mutation sendAnalytics($input: SendAnalyticsRequest!) {
    sendAnalytics(input: $input)
  }
`)
