"use client"

import * as React from "react"
import { useTheme } from "next-themes"

import { Icons } from "@/components/icons"
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

export function ThemeToggle() {
  const { setTheme } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="icon">
          <Icons.sun className="rotate-0 scale-100 transition-all" />
          <Icons.moon className="absolute rotate-90 scale-0 transition-all" />
          <span className="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        collisionPadding={{
          left: 24,
          right: 24,
        }}
      >
        <DropdownMenuItem
          className="flex cursor-pointer items-center gap-1"
          data-ph-event="change_theme"
          data-ph-value="light"
          onClick={() => setTheme("light")}
        >
          <Icons.sun className="size-4" />
          Light
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-center gap-1"
          data-ph-event="change_theme"
          data-ph-value="dark"
          onClick={() => setTheme("dark")}
        >
          <Icons.moon className="size-4" />
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-center gap-1"
          data-ph-event="change_theme"
          data-ph-value="system"
          onClick={() => setTheme("system")}
        >
          <Icons.laptop className="size-4" />
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
