import { forwardRef, Ref, SVGProps } from "react"

interface SVGRProps {
  title?: string
  titleId?: string
}
const SvgComponent = (
  {
    title = "logo-icon",
    titleId = "logo-icon",
    ...props
  }: SVGProps<SVGSVGElement> & SVGRProps,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 512 512"
    ref={ref}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <mask
      id="logo-icon-a"
      width={366}
      height={239}
      x={73}
      y={137}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M439 137H73v238h366V137Z" />
    </mask>
    <g mask="url(#logo-icon-a)">
      <path
        fill="url(#logo-icon-b)"
        d="m247 315-99 53c-14 8-31 10-46 4-20-8-29-25-29-50V194l96-50c10-6 23-8 35-6 37 6 43 29 43 52v125Z"
      />
      <path
        fill="url(#logo-icon-c)"
        d="M145 244v125c-13 7-29 8-43 3-20-8-29-25-29-50V195c9-4 19-5 29-4 37 6 43 30 43 53Z"
        opacity={0.5}
      />
      <path
        fill="url(#logo-icon-d)"
        d="m343 315-99 53c-15 8-31 10-47 4-19-8-28-25-28-50V194l96-50c10-6 23-8 35-6 37 6 43 29 43 52v125Z"
      />
      <path
        fill="url(#logo-icon-e)"
        d="M241 244v125c-13 7-29 8-44 3-19-8-28-25-28-50V195c9-4 19-5 29-4 37 6 43 30 43 53Z"
        opacity={0.5}
      />
      <path
        fill="url(#logo-icon-f)"
        d="m439 315-99 53c-15 8-32 10-47 4-19-8-28-25-28-50V194l95-50c11-6 23-8 35-6 38 6 44 29 44 52v125Z"
      />
      <path
        fill="url(#logo-icon-g)"
        d="M337 244v125c-14 7-29 8-44 3-19-8-28-25-28-50V194c9-3 19-5 29-3 37 6 43 30 43 53Z"
        opacity={0.5}
      />
    </g>
    <defs>
      <linearGradient
        id="logo-icon-b"
        x1={131}
        x2={206.3}
        y1={322.7}
        y2={159.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7560B2" />
        <stop offset={1} stopColor="#2B3392" />
      </linearGradient>
      <linearGradient
        id="logo-icon-c"
        x1={109.2}
        x2={109.2}
        y1={284}
        y2={193.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#7560B2" />
        <stop offset={0.7} stopColor="#3F3F9B" />
        <stop offset={1} stopColor="#2B3392" />
      </linearGradient>
      <linearGradient
        id="logo-icon-d"
        x1={202.4}
        x2={346}
        y1={296.1}
        y2={193.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DE6E7F" />
        <stop offset={1} stopColor="#CF5164" />
      </linearGradient>
      <linearGradient
        id="logo-icon-e"
        x1={205.1}
        x2={205.1}
        y1={286}
        y2={195.7}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DE6E7F" />
        <stop offset={1} stopColor="#CF5164" />
      </linearGradient>
      <linearGradient
        id="logo-icon-f"
        x1={284.4}
        x2={427.7}
        y1={300.1}
        y2={211.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2BB65" />
        <stop offset={0.3} stopColor="#F1B663" />
        <stop offset={0.6} stopColor="#F0AA60" />
        <stop offset={0.9} stopColor="#EF965A" />
        <stop offset={1} stopColor="#EF9359" />
      </linearGradient>
      <linearGradient
        id="logo-icon-g"
        x1={301}
        x2={301}
        y1={312.6}
        y2={222.2}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F2BB65" />
        <stop offset={0.3} stopColor="#F1B663" />
        <stop offset={0.6} stopColor="#F0AA60" />
        <stop offset={0.9} stopColor="#EF965A" />
        <stop offset={1} stopColor="#EF9359" />
      </linearGradient>
    </defs>
  </svg>
)
const ForwardRef = forwardRef(SvgComponent)
export { ForwardRef as LogoIcon }
